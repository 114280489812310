import { UserOutlined } from "@ant-design/icons";
import { TeamOutlined } from "@ant-design/icons";
import { Menu, Dropdown, Col, Avatar } from "antd";
import { useState, useEffect, useRef } from "react";
import { Logout } from "react-iconly";
import { get, post } from "../../../api/HTTPService";
import ResetPassword from "../../../view/pages/authentication/resetPassword";
require("dotenv").config();

let name = sessionStorage.getItem("name");
let role = sessionStorage.getItem("currentUserRole");

// import avatarImg from "../../../assets/images/memoji/memoji-1.png";

export default function HeaderUser() {
  const [group, setGroup] = useState();
  const [entity, setEntity] = useState();
  const [selectedGroupFile, setSelectedGroupFile] = useState({
    file: "",
    fileName: "",
  });
  const [selectedEntityFile, setSelectedEntityFile] = useState({
    file: "",
    fileName: "",
  });
  const [loading, setloading] = useState(false);
  const [resetPassword, setResetPassword] = useState(false);

  const inputGroupRef = useRef(null);
  const inputEntityRef = useRef(null);

  const logout = () => {
    sessionStorage.removeItem("token");
    window.location.href =
      process.env.NODE_ENV == "local"
        ? "https://login.xecurify.com/moas/broker/login/jwt/logout/292227?client_id=y8xUiGfCVuS2Hp52&redirect_uri=http://localhost:3000/"
        : `https://login.xecurify.com/moas/broker/login/jwt/logout/292227?client_id=epGmJWZy5q0lc88e&redirect_uri=https://compliance.esgfit.in/`;
  };

  const getGroup = () => {
    get("/users/fetch_assignments").then((res) => {
      console.log("ressss", res);
      setGroup(res.groups[0]);
      setEntity(res.entities[0]);
    });
  };

  useEffect(() => {
    getGroup();
  }, []);

  const onGroupChange = async (info) => {
    console.log("onchange", info);
    if (info) {
      setSelectedGroupFile({
        file: info,
        fileName: info.name,
      });
    }
  };

  useEffect(async () => {
    if (selectedGroupFile.fileName) {
      setloading(true);
      const formData = new FormData();
      formData.append("file", selectedGroupFile.file);

      try {
        const uploadFile = await post(
          "/upload/groupLogo/" + group.id,
          formData
        );
        if (uploadFile) {
          alert("Image Uploaded");
          window.location.reload();
        }
        setloading(false);
      } catch (e) {
        setloading(false);
        console.log("something went wrong while uploading file");
      }
    }
  }, [selectedGroupFile]);
  const onEntityChange = async (info) => {
    console.log("onchange", info);
    if (info) {
      setSelectedEntityFile({
        file: info,
        fileName: info.name,
      });
    }
  };

  useEffect(async () => {
    if (selectedEntityFile.fileName) {
      setloading(true);
      const formData = new FormData();
      formData.append("file", selectedEntityFile.file);

      try {
        const uploadFile = await post(
          "/upload/groupLogo/" + entity.id,
          formData
        );
        if (uploadFile) {
          alert("Image Uploaded");
          window.location.reload();
        }
        setloading(false);
      } catch (e) {
        setloading(false);
        console.log("something went wrong while uploading file");
      }
    }
  }, [selectedEntityFile]);
  const menu = (
    <Menu style={{ left: "0px", width: "225px" }}>
      <Menu.Item style={{ pointerEvents: "none" }}>
        <UserOutlined />
        &nbsp;&nbsp;<b>Name : </b>
        {name}
      </Menu.Item>
      <Menu.Item style={{ pointerEvents: "none" }}>
        {/* <UserOutlined /> */}
        <TeamOutlined />
        &nbsp;&nbsp;<b>Role &nbsp;&nbsp;&nbsp;: </b> {role}
      </Menu.Item>
      {/* <Menu.Item
        icon={
          <Calendar
            set="curved"
            className="remix-icon da-vertical-align-middle"
            size={16}
          />
        }
      >
        <Link to="/apps/calendar">Calendar</Link>
      </Menu.Item> */}
      {role === "GROUP_ADMIN" && (
        <Menu.Item onClick={() => inputGroupRef.current.click()}>
          <p>Upload Group Logo</p>
          <input
            type="file"
            name="file"
            ref={inputGroupRef}
            style={{ display: "none" }}
            onChange={(e) => onGroupChange(e.target.files[0])}
            onClick={(event) => {
              event.currentTarget.value = null;
            }}
          />
        </Menu.Item>
      )}
      {role === "ENTITY_ADMIN" && (
        <Menu.Item onClick={() => inputEntityRef.current.click()}>
          <p>Upload Entity Logo</p>
          <input
            type="file"
            name="file"
            ref={inputEntityRef}
            style={{ display: "none" }}
            onChange={(e) => onEntityChange(e.target.files[0])}
            onClick={(event) => {
              event.currentTarget.value = null;
            }}
          />
        </Menu.Item>
      )}
      <Menu.Item>
        <a href={process.env.REACT_APP_CRM_URL} target={"_blank"}>
          CRM
        </a>
      </Menu.Item>
      <Menu.Item>
        <a href={process.env.REACT_APP_LMS_URL} target={"_blank"}>
          LMS
        </a>
      </Menu.Item>
      {/* <Menu.Item>
        <a href={"https://www.sejda.com/pdf-editor"} target={"_blank"}>
          Digitization Center
        </a>
      </Menu.Item> */}
      <Menu.Item>
        <p onClick={() => setResetPassword(true)}>Reset Password</p>
      </Menu.Item>
      <Menu.Item
        icon={
          <Logout
            set="curved"
            className="remix-icon da-vertical-align-middle"
            size={16}
          />
        }
        onClick={logout}
      >
        <span>Logout</span>
      </Menu.Item>
    </Menu>
  );

  return (
    <>
      <Dropdown overlay={menu}>
        <Col className="da-d-flex-center" onClick={(e) => e.preventDefault()}>
          <Avatar icon={<UserOutlined />} size={40} />
        </Col>
      </Dropdown>
      <ResetPassword reset={resetPassword} setReset={setResetPassword} />
    </>
  );
}
